


























































import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import IMask from 'imask';

// TODO: Make some description about how does it works.
// Currency format cant be used with number input
// Refactor this someday please
@Component({
  components: {SygniInputError}
})
export default class SygniInput extends mixins(Vue, SygniValidation) {
  @Prop({ default: false }) useInvalidProperty: boolean;
  @Prop({ default: false }) clearable: boolean;
  @Prop({default: null}) max: number | null;
  @Prop() inputLength: string;
  @Prop() errorMessage: string;
  @Prop({ default: null }) tooltip: string;
  @Prop({ default: null }) nativeTooltip: string;
  @Prop({ default: 'default' }) errorType: string;
  @Prop() label!: string | number;
  @Prop() value!: string | number;
  @Prop({ default: false }) mapNull: boolean;
  @Prop({ default: false }) checkLength: boolean;
  @Prop({ default: null }) transform?: 'lowercase' | null;
  @Prop({ default: null }) testId: string;
  @Watch('value', {immediate: true}) onValueChange() {
    if(!this.value) {
      this.modelValue = '';
      this.displayValue = '';
      return;
    }
    if(this.value !== this.modelValue){
      this.modelValue = this.value;
      this.displayValue = this.applyDisplayFormat(String(this.modelValue));
    }
    if (this.checkLength) {
      if (this.inputLength) {
        if (this.inputLength != (this.modelValue as any)?.length) {
          this.hasLengthError = true;
        } else {
          this.hasLengthError = false;
        }
      }
    }
  }
  @Watch('disabled') onDisabledChange() {
    this.isDisabled = this.disabled;
  }
  @Watch('mask') onMaskChange() {
    this.setInputMask();
  }
  @Prop({default: 'text'}) type: string;
  @Prop() unit!: string;
  @Prop() displayFormat!: string;
  @Prop({default: false}) showArrows: boolean;
  @Prop({default: false}) editable: boolean;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: false}) displayCheck: boolean;
  @Prop({default: false}) mask: boolean | string;

  isDisabled: boolean = false;
  selectionStart: number = 0;
  selectionEnd: number = 0;
  maskInstance?: object = null;
  hasLengthError: boolean = false;

  get sygniInputClassList(): string {
    let classList = '';
    if(this.isDisabled) classList += ' disabled';
    return classList;
  }

  modelValue: string | number = '';
  displayValue: string = '';

  @Emit('input')
  handleInput(event: any, value: string | number): string | number {
    const selectionStart = event && event?.inputType !== 'insertFromPaste' ? event.target.selectionStart : false;
    const selectionEnd = event && event?.inputType !== 'insertFromPaste' ? event.target.selectionEnd : false;

    let unformattedValue = this.unapplyDisplayFormat(value);
    if(this.displayFormat === 'currency' || this.displayFormat === 'number' || this.displayFormat === 'integer'){
      this.displayValue = String(value);
    } else {
      this.displayValue = this.applyDisplayFormat(String(value));
    }
    if (this.type === 'number' || this.displayFormat === 'number' || this.displayFormat === 'integer') {
      unformattedValue = Number(unformattedValue);
    } else if (this.displayFormat === 'currency') {
      unformattedValue = Number(this.$options.filters.numberFormat(unformattedValue, 2)?.replaceAll(' ', '')?.replaceAll(',', '.'));
    } else {
      unformattedValue = String(unformattedValue);
    }

    this.modelValue = unformattedValue;

    if(selectionStart && selectionEnd) {
      this.selectionStart = selectionStart;
      this.selectionEnd = selectionEnd;
    } else {
      this.selectionStart = 0;
      this.selectionEnd = 0;
    }

    if(this.mapNull) {
      if(unformattedValue === '') {
        unformattedValue = null;
      }
    }

    if (this.inputLength) {
      if (this.inputLength != (this.modelValue as any)?.length) {
        this.hasLengthError = true;
      } else {
        this.hasLengthError = false;
      }
    }

    return unformattedValue;
  }

  enableInput() {
    this.isDisabled = false;
    this.$nextTick(() => {
      (this.$refs.sygniInputEl as HTMLInputElement).focus();
    });
  }

  removeKey(object: any, propName: string){
    return this.$options.filters.removeKey(object, propName);
  }

  setInputMask() {
    if (this.mask === 'iban') {
      this.$nextTick(() => {
        this.maskInstance = IMask(this.$refs.sygniInputEl as HTMLInputElement, {
            mask: '[aa]** **** **** **** **** **** **** ****',
          }
        );
        if (this.mask === 'iban') {
          this.modelValue = this.$options.filters.bankAccountNumber(this.modelValue)
          this.displayValue = this.$options.filters.bankAccountNumber(this.displayValue)
        }
      })
    } else if(this.mask === 'threeDigitNumber') {
      this.$nextTick(() => {
        this.maskInstance = IMask(this.$refs.sygniInputEl as HTMLInputElement, {
          mask: '***',
        })
      })
    } else if(this.mask === 'rate') {
      this.$nextTick(() => {
        this.maskInstance = IMask(this.$refs.sygniInputEl as HTMLInputElement, {
          mask: Number,
          scale: 4,
          thousandsSeparator: ' ',
          padFractionalZeros: false,
          normalizeZeros: true,
        })
      })
    } else {
      this.$nextTick(() => {
        this.destroyInputMask();
      })
    }
  }

  destroyInputMask() {
    (this.maskInstance as any)?.destroy();
    this.maskInstance = null
  }

  created() {
    this.modelValue = this.value;
    this.isDisabled = this.disabled;
  }

  mounted() {
    this.setInputMask();

    if (this.inputLength) {
      if (this.inputLength != (this.modelValue as any)?.length) {
        this.hasLengthError = true;
      } else {
        this.hasLengthError = false;
      }
    }
  }

  increment() {
    (this.modelValue as number)++;
    this.handleInput(null, this.modelValue);
  }

  decrement() {
    (this.modelValue as number)++;
    this.handleInput(null, this.modelValue);
  }

  applyDisplayFormat(value: string): string{
    if(!value) return '';
    if(this.displayFormat === 'currency'){
      return this.$options.filters.numberFormat(value ,2, true);
    } else if(this.displayFormat === 'bankAccount') {
      return this.$options.filters.bankAccountNumber(value);
    }
    if (this.displayFormat === 'number') {
      const length = this.countDecimals(Number(value));
      return this.$options.filters.numberFormat(value, length > 1 ? length : 2, true);
    }
    if (this.displayFormat === 'capitalize') {
      return this.$options.filters.capitalizeFirstLetter(value)
    }
    if (this.displayFormat === 'toPhrase') {
      return this.$options.filters.toPhrase(value)
    }
    if (this.displayFormat === 'url') {
      return this.$options.filters.toHttps(value)
    }
    if (this.displayFormat === 'integer') {
      return this.$options.filters.thousandSeparator(value)
    }
    return value;
  }

  unapplyDisplayFormat(value: any): any{
    if(this.displayFormat === 'currency' || this.displayFormat === 'integer' || this.displayFormat === 'number'){
      return Number(String(value).replaceAll(' ', '').replaceAll(',','.'));
    }

    return value;
  }

  countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split(".")[1]?.length || 0;
  }

  onBlur(e: any): void {
    this.$v?.$touch();
    if (this.displayFormat === 'number') {
      const length = this.countDecimals(Number(this.modelValue));
      this.displayValue = this.$options.filters.numberFormat(this.modelValue, length > 1 ? length : 2, true);
    }
    if(this.max !== null) {
      if(this.modelValue > this.max) {
        this.modelValue = this.max;
      }
    }
    if (this.transform === 'lowercase') {
      this.value = String(this.modelValue)?.toLowerCase()
    }
    if( this.displayFormat === 'currency'){
      this.displayValue = this.$options.filters.numberFormat(this.modelValue,2, true);
    }
    if( this.displayFormat === 'integer'){
      this.displayValue = this.$options.filters.thousandSeparator(this.modelValue);
    }
    if(this.mask === 'threeDigitNumber') {
      this.displayValue = this.$options.filters.leadingZeroDigitFormat(this.modelValue, 3);
      this.handleInput(e, this.$options.filters.leadingZeroDigitFormat(this.modelValue, 3));
    }
  }

  getValidationError(validation: any) {
    if (validation.required === false) {
      return 'This field is required';
    }
    if (validation.minLength === false) {
      return `Minimum length is ${validation.$params.minLength.min}`;
    }
    if (validation.maxLength === false) {
      return `Maximum length is ${validation.$params.maxLength.max}`;
    }
    if (validation.email === false) {
      return 'This field must be valid email';
    }
    if (validation.strongPassword === false) {
      return 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.';
    }
    if (validation.sameAsPassword === false) {
      return 'Passwords must be same';
    }
    if (validation.minValue === false) {
      return `Minimum value is ${validation.$params.minValue.min}`;
    }
    if (validation.wrongDate === false) {
      return 'Invalid date';
    }
    if (validation.zipCode === false) {
      return 'Provide correct zip code';
    }
    if (validation.bankAccount === false) {
      return 'Incorrect bank account number';
    }
    if (validation.checked === false) {
      return 'This field must be checked';
    }
    if (validation.phone === false) {
      return 'Invalid phone number';
    }
    if (validation.url === false) {
      return 'Invalid url';
    }
    if (validation.iban === false) {
      return 'Invalid Bank Account Number';
    }

    return '';
  }

  setFocus(): void {
    (this.$refs.sygniInputEl as any).focus();
    (this.$refs.sygniInputEl as any).selectionStart = this.displayValue.length;
    (this.$refs.sygniInputEl as any).selectionEnd = this.displayValue.length;
  }

  onSubmit(): void {
    this.$emit('submit');
  }
}
